import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  InputAdornment,
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Modal,
} from "@mui/material";
import moment from "moment";

import { getAuditById, getListOfAllAudits } from "../../reducers/audit.reducer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuditList,
  selectAuditListForFactory,
} from "../../selectors/audit.selector";
import {
  selectFactoryData,
  selectFactoryList,
} from "../../selectors/factory.selector";
import { selectSupplierId } from "../../selectors/login.selector";

import { getListOfAllFactories } from "../../reducers/factory.reducer";
import ListCard from "../listCard.component";
import AuditDocumentDrop from "../AuditDetails/auditDocumentDrop.component";
import { ComponentStyles } from "../../styles";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      color: "#344054",
      fontSize: "14px",
      borderRadius: "12px",
    },
  },
};

const FactoryAuditListCard = ({ selectAudits }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const [personName, setPersonName] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);

  console.log("selectAudits KH", selectAudits);

  const sortedAudits = selectAudits
    .sort((a, b) => new Date(b.lastAuditDate) - new Date(a.lastAuditDate));

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = (files) => {
    console.log(files);
    setFiles(files);
    setOpen(false);
  };

  const selectFactories = useSelector(selectFactoryList);
  const supplierId = useSelector(selectSupplierId);
  const organisationId = params.organisationId;
  const factoryId = params.factoryId;
  
  // useEffect(() => {
  //   if (selectFactories.length === 0) {
  //     dispatch(getListOfAllFactories());
  //   }
  // }, [selectFactories]);
  const factoryIdToFactoryName = selectFactories.reduce((acc, factory) => {
    acc[factory.factoryId] = factory.name;
    return acc;
  }, {});

  //   const handleChange = (event) => {
  //     setaudit(event.target.value);
  //   };
  const issuesColorScheme = (status) => {
    switch (status) {
      case "zt":
        return "#B42318";
      case "critical":
        return "#FF692E";
      case "major":
        return "#EAAA08";
      default:
        return "#667085";
    }
  };

  // const handleFilterChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(typeof value === "string" ? value.split(",") : value);
  // };
  const handleNavigationToAuditDetails = (factoryId, auditId) => {
    dispatch(
      getAuditById({
        supplierId: supplierId,
        factoryId: factoryId,
        auditId: auditId,
      })
    );
    navigate(`${location.pathname}/audit/${auditId}`);
  };

  const factoryData = useSelector(selectFactoryData);

  return (
    <>

      {sortedAudits?.length > 0 &&
        sortedAudits.map((audit) => (
          <Paper
            elevation={1}
            sx={{
              py: 3,
              px: 4,
              my: 4,
              boxShadow: "none",
              height: "150px",
              overflowY: "hidden",
              borderRadius: "12px",
              border: "1px solid #EAECF0",
              background: "#FFF",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              cursor: "pointer",
            }}
            key={audit.auditId}
            onClick={() =>
              handleNavigationToAuditDetails(audit.factoryId, audit.auditId)
            }
          >
            <Grid
              container
              spacing={0}
              sx={ComponentStyles.alignBoxItemsCenter}
            >
              <Grid item xs={1}>
                {audit.state !== "in progress" && (
                  <Typography
                    sx={{
                      color: "#3538CD",
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  >
                    {audit.year}
                  </Typography>
                )}
                {audit?.state && audit.state === "test" && (
                  <Typography color="red" sx={{ fontSize: "10px" }}>
                    Internal - Not Approved
                  </Typography>
                )}
              </Grid>
              <Grid item xs={11}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 18,
                    color: "#000",
                    display: "inline-block",
                    mr: 2,
                    mb: 2,
                  }}
                >
                  {Object.keys(factoryIdToFactoryName).length > 0
                    ? factoryIdToFactoryName[audit.factoryId]
                    : factoryData?.name}
                </Typography>

                <ListCard data={audit} type="audit" />
              </Grid>
            </Grid>
          </Paper>
        ))}
    </>
  );
};

export default FactoryAuditListCard;