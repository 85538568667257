import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectFactoryActionNeededData,
  selectFactoryData,
} from "../../selectors/factory.selector";
import {
  selectAuditListForFactory,
  selectCapListForFactory,
  selectAuditCAPStatus
} from "../../selectors/audit.selector";

import FactoryActionNeeded from "./factoryActionNeeded.component";
import FacilityAuditScheduling from "./facilityAuditScheduling.component";

import FactoryMostRecentAuditDetails from "./factoryMostRecentAuditDetails.component";

const FacilityComplianceStatus = () => {
  const params = useParams();
  const selectFactory = useSelector(selectFactoryData);
  const auditCAPStatus = selectFactory?.latestAudit?.capManagementStatus;
  const factoryCapData = useSelector((state) =>
    selectCapListForFactory(state, params.factoryId)
  );

  // Filter out items with status "Open"
  const openCapData = factoryCapData.filter((item) => item.status === "Open");

  return (
    <Box sx={{ mb: 5 }}>
      <FacilityAuditScheduling selectedFactory={selectFactory} />

      <FactoryMostRecentAuditDetails selectedFactory={selectFactory} />

      <Grid item>
        {openCapData?.length > 0 && auditCAPStatus !== "OPEN" && (
          <FactoryActionNeeded capDetails={openCapData} />
        )}
      </Grid>
    </Box>
  );
};

export default FacilityComplianceStatus;
