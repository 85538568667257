import React, { useState } from "react";
import { Grid, Typography, Chip, TextField, Box, Tooltip } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

import CustomChipLabel from "./customChipLabel.component";
import { AuditStyles, ComponentStyles } from "../../styles";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const AuditReportCapDetailsSection = ({ data, auditCapStatus }) => {
  const [note, setNote] = useState("");
  const [saveStatus, setSaveStatus] = useState("");

  const handleNoteChange = (event) => {
    setNote(event.target.value);
    // Implement save logic here
    setSaveStatus("saving");
    // Simulating save completion after 1 second
    setTimeout(() => setSaveStatus("saved"), 1000);
  };

  const AiGeneratedField = ({ label, children }) => (
    <Box>
      <Typography
        sx={{
          color: "#475467",
          fontSize: "16px",
          fontWeight: 700,
          mb: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        {label}
        <Tooltip title="Generated by AI">
          <AutoAwesomeIcon sx={{ fontSize: 16, ml: 0.5, color: "darkgrey" }} />
        </Tooltip>
      </Typography>
      {children}
    </Box>
  );
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {auditCapStatus !== "OPEN" && (
          <>
            <Grid item xs={4}>
              <Typography sx={AuditStyles.fieldTitle}>Finding ID</Typography>
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {data?.clientCodeTitle || "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={AuditStyles.fieldTitle}>Category</Typography>
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {data?.type || "-"}
              </Typography>
            </Grid>
            {/* <Grid item xs={3}>
              <Typography sx={AuditStyles.fieldTitle}>Subcategory</Typography>
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {data?.subType || "-"}
              </Typography>
            </Grid> */}
            {/* <Grid item xs={3}>
              <Typography sx={AuditStyles.fieldTitle}>
                Repeat Finding
              </Typography>
              <Chip
                label={data?.repeatedIssue || "No"}
                sx={{
                  fontSize: "14px",
                  color: "#344054",
                  fontWeight: 500,
                  textAlign: "center",
                  borderRadius: "6px",
                  border: "1px solid #D0D5DD",
                  background: "#FFF",
                  padding: "4px 10px",
                }}
              />
            </Grid> */}
          </>
        )}

        <Grid item xs={12} sx={{ my: 2 }}>
          <Typography sx={{ ...AuditStyles.fieldTitle, mb: 1 }}>
            Finding Details
          </Typography>
          <Typography sx={AuditStyles.auditReportFieldValue}>
            {data?.issueDetail || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography sx={{ ...AuditStyles.fieldTitle, mb: 1 }}>
            Audit Code or Legal Reference
          </Typography>
          <Typography sx={AuditStyles.auditReportFieldValue}>
            {data?.auditBasis || "-"}
          </Typography>
        </Grid>
        {/* {auditCapStatus !== "OPEN" && (
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography sx={{ ...AuditStyles.fieldTitle, mb: 1 }}>
              Match From Master Finding List
            </Typography>
            <Typography sx={AuditStyles.auditReportFieldValue}>
              {data?.clientCode || "-"}
            </Typography>
          </Grid>
        )} */}
      </Grid>

      {/* {auditCapStatus !== "OPEN" && (
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={6} sm={6} md={4}>
            <AiGeneratedField label="Criticality">
              <Typography variant="body1" sx={{ color: "error.main" }}>
                <CustomChipLabel severity={data.severity} />
              </Typography>
            </AiGeneratedField>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <AiGeneratedField label="Remediation Timeline">
              <Typography
                sx={{
                  ...AuditStyles.auditReportFieldValue,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data?.timeline || "-"}
              </Typography>
            </AiGeneratedField>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={7}>
            <AiGeneratedField label="Rating Explanation">
              <Typography
                sx={{ color: "#45464E", fontSize: "16px", fontWeight: 400 }}
              >
                {data?.ratingExplanation || "-"}
              </Typography>
            </AiGeneratedField>
          </Grid> 
        </Grid>
      )} */}
    </>
  );
};

export default AuditReportCapDetailsSection;
