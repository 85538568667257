import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  InputAdornment,
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Modal,
} from "@mui/material";
import moment from "moment";

import {
  getAuditById,
  getListOfAllAudits,
  setModalStateForManageCAPs,
} from "../../reducers/audit.reducer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuditCAPStatus,
  selectAuditList,
  selectAuditListForFactory,
} from "../../selectors/audit.selector";
import { selectFactoryList } from "../../selectors/factory.selector";
import {
  getListOfAllFactories,
  setFactoryData,
} from "../../reducers/factory.reducer";
import ListCard from "../listCard.component";
import AuditDocumentDrop from "../AuditDetails/auditDocumentDrop.component";
import { ComponentStyles } from "../../styles";
import { selectSupplierId } from "../../selectors/login.selector";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      color: "#344054",
      fontSize: "14px",
      borderRadius: "12px",
    },
  },
};

const AuditListInCapManagementProgress = ({ selectAudits }) => {
  console.log(selectAudits);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const [personName, setPersonName] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);

  //   const sortedAudits = selectAudits.sort(
  //     (a, b) => new Date(b.lastAuditDate) - new Date(a.lastAuditDate)
  //   );
  const supplierId = useSelector(selectSupplierId);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = (files) => {
    console.log(files);
    setFiles(files);
    setOpen(false);
  };
  const selectFactories = useSelector(selectFactoryList);
  const organisationId = params.organisationId;

  const factoryId = params.factoryId;
  // useEffect(() => {
  //   if (selectFactories.length === 0) {
  //     dispatch(getListOfAllFactories());
  //   }
  // }, [selectFactories]);

  //   const handleChange = (event) => {
  //     setaudit(event.target.value);
  //   };
  const issuesColorScheme = (status) => {
    switch (status) {
      case "zt":
        return "#B42318";
      case "critical":
        return "#FF692E";
      case "major":
        return "#EAAA08";
      default:
        return "#667085";
    }
  };

  // const handleFilterChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(typeof value === "string" ? value.split(",") : value);
  // };

  const handleNaivgationToAuditDetails = (factoryId, auditId, factoryData) => {
    dispatch(setModalStateForManageCAPs(false));
    dispatch(setFactoryData(factoryData));

    dispatch(
      getAuditById({
        supplierId: supplierId,
        factoryId: factoryId,
        auditId: auditId,
      })
    );
    navigate(`/factories/${factoryId}/audit/${auditId}`);
  };

  const subHeaderText = {
    0: "Audit Review",
    1: "CAP Input",
    2: "CAP Review",
    3: "Additional Information Needed",
    4: "CAP Closure Review",
  };
  const mappingOfCAPStatusToStep = {
    OPEN: 0,
    IN_PROGRESS: 1,
    BRAND_REVIEWING_PLAN: 2,
    BRAND_APPROVED_PLAN: 3,
    SITE_ADDING_PROOF: 3,
    SITE_ADDED_PROOF: 3,
    BRAND_APPROVING_PROOF: 3,
    BRAND_APPROVED_PROOF: 5,
    CLOSED: 5,
  };

  return (
    <>
      {selectAudits?.length > 0 &&
        selectAudits.map((audit) => (
          <Paper
            elevation={1}
            sx={{
              py: 3,
              px: 4,
              my: 4,
              boxShadow: "none",
              height: "150px",
              overflowY: "hidden",
              borderRadius: "12px",
              border: "1px solid #EAECF0",
              background: "#FFF",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              cursor: "pointer",
            }}
            key={audit.auditId}
            onClick={() =>
              handleNaivgationToAuditDetails(
                audit.factoryId,
                audit.auditId,
                audit.factoryData
              )
            }
          >
            <Grid
              container
              spacing={0}
              sx={ComponentStyles.alignBoxItemsCenter}
            >
              <Grid item xs={1}>
                <Typography
                  sx={{
                    color: "#45464E",
                    color: "#3538CD",
                    fontSize: "18px",
                    fontWeight: 600,
                  }}
                >
                  {audit.year}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    background:
                      mappingOfCAPStatusToStep[audit.capManagementStatus] ===
                        0 ||
                      mappingOfCAPStatusToStep[audit.capManagementStatus] ===
                        2 ||
                      mappingOfCAPStatusToStep[audit.capManagementStatus] === 4
                        ? "linear-gradient(to right, #027A48, #FFFFFF)"
                        : "linear-gradient(to right, #B42318, #FFFFFF)",
                    color: "#FFF",
                    borderRadius: "6px",
                  }}
                >
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        mb: 0.5,
                        pl: 0.5,
                      }}
                    >
                      {mappingOfCAPStatusToStep[audit.capManagementStatus] ===
                        0 ||
                      mappingOfCAPStatusToStep[audit.capManagementStatus] ===
                        2 ||
                      mappingOfCAPStatusToStep[audit.capManagementStatus] === 4
                        ? "4imprint"
                        : "Supplier"}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "14px", mb: 0.5, pl: 0.5 }}
                    >
                      {
                        subHeaderText[
                          mappingOfCAPStatusToStep[audit.capManagementStatus]
                        ]
                      }
                    </Typography>
                  </Box>
                </Box>
                {audit?.state && audit.state === "test" && (
                  <Typography color="red" sx={{ fontSize: "10px" }}>
                    Internal - Not Approved
                  </Typography>
                )}
              </Grid>
              <Grid item xs={11}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 18,
                    color: "#000",
                    display: "inline-block",
                    mr: 2,
                    mb: 2,
                  }}
                >
                  {audit.factoryName}
                </Typography>

                <ListCard data={audit} type="audit" />
              </Grid>
            </Grid>
          </Paper>
        ))}
    </>
  );
};

export default AuditListInCapManagementProgress;
