import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Button, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { selectSupplierData } from "../../selectors/supplier.selector";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import SupplierInformationDrawer from "./supplierInformationDrawer.component";
import { ComponentStyles, SupplierStyles } from "../../styles";

const SupplierInformation = () => {
  const supplierData = useSelector(selectSupplierData);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  console.log(supplierData);

  // Helper function to validate additional contacts
  const isValidContact = (contact) => {
    return contact && Object.values(contact).some(value => value && value.trim() !== '');
  };

  return (
    <Card style={SupplierStyles.supplierInformationCard}>
      {supplierData && (
        <CardContent sx={{ minHeight: "205px" }}>
          <Grid container justifyContent="space-between" sx={{ mb: 2 }}>
            <Grid item>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  color: "#344054",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                General Supplier Information
                <Tooltip 
                  title="This information is maintained in the 4imprint database. To request updates, click the “Send 4imprint a note” button in the top-right corner."
                  placement="right"
                >
                  <InfoOutlinedIcon
                    sx={{ marginLeft: "8px", color: "#A0A5AD", verticalAlign: "middle" }}
                  />
                </Tooltip>
              </Typography>
            </Grid>

            <Grid item>
              {/* <Button
                variant="outlined"
                onClick={toggleDrawer(true)}
                sx={{
                  color: "#6172F3",
                  "&:hover": {
                    color:"white",
                    backgroundColor: "#5667e2",
                  },
                  textTransform: "none",
                  width: "150px",
                  height: "40px",
                  borderRadius: "8px",
                  mr: 1
                }}
              >
                More Information
              </Button> */}

              {/* <Button
                variant="outlined"
                onClick={toggleTierMapDrawer(true)}
                sx={{
                  color: "#6172F3",
                  "&:hover": {
                    color:"white",
                    backgroundColor: "#5667e2",
                  },
                  textTransform: "none",
                  width: "100px",
                  height: "40px",
                  borderRadius: "8px",
                  mr:"-24px"
                }}
              >
                Tier Map
              </Button> */}
            </Grid>
          </Grid>
           {/* Company Address */}
           <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>
                {" "}
                Supplier ID
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {supplierData?.metadata?.uniqueSupplierId}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>
                {" "}
                Street Address
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {supplierData?.metadata?.streetAddress || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>
                {" "}
                City, State/Province, Country
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {`${supplierData?.metadata?.city || "-"}, ${
                  supplierData?.metadata?.stateProvince || "-"
                }, ${supplierData?.metadata?.country || "-"}`}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}> Zip Code</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {supplierData?.metadata?.zipCode || "-"}
              </Typography>
            </Grid>
          </Grid>
          {/* Company information */}
          
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>
                Primary Contact Person
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {supplierData?.metadata?.contactPerson?.name || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Position</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {supplierData?.metadata?.contactPerson?.position || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Phone</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {supplierData?.metadata?.contactPerson?.phone || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Email</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {supplierData?.metadata?.contactPerson?.email || "-"}
              </Typography>
            </Grid>
          </Grid>

          {/* Additional Contacts */}
          {supplierData?.metadata?.additionalContacts?.length > 0 && (
            supplierData.metadata.additionalContacts.map((contact, index) => (
              isValidContact(contact) && (
                <Grid container spacing={0} justifyContent="space-between" key={index} sx={{ mt: 2 }}>
                  <Grid item xs={3}>
                    <Typography sx={ComponentStyles.fieldName}> Additional Contact Person</Typography>
                    <Typography sx={{ ...ComponentStyles.fieldValue, wordBreak: "break-word" }}>
                      {contact.contactPersonName || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={ComponentStyles.fieldName}>Position</Typography>
                    <Typography sx={{ ...ComponentStyles.fieldValue, wordBreak: "break-word" }}>
                      {contact.contactPersonPosition || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={ComponentStyles.fieldName}>Phone</Typography>
                    <Typography sx={{ ...ComponentStyles.fieldValue, wordBreak: "break-word" }}>
                      {contact.contactPersonPhone || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={ComponentStyles.fieldName}>Email</Typography>
                    <Typography sx={{ ...ComponentStyles.fieldValue, wordBreak: "break-word" }}>
                      {contact.contactPersonEmail || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              )
            ))
          )}
        </CardContent>
      )}
      <SupplierInformationDrawer
        supplierData={supplierData}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
      />
    </Card>
  );
};

export default SupplierInformation;
