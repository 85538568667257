import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Paper, Grid, Tooltip } from "@mui/material";
import CapManagementModal from "../components/capManagementModal.component";
import {
  selectIdOfMostRecentAudit,
  selectModalStateForManageCAPs,
} from "../selectors/audit.selector";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getListOfAuditsInCapManagement,
  setModalStateForManageCAPs,
} from "../reducers/audit.reducer";
import { selectSupplierId } from "../selectors/login.selector";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const FactoryCAPStatus = ({ status }) => {
  const params = useParams();
  const factoryId = params.factoryId;
  const supplierId = useSelector(selectSupplierId);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const modalStateForManageCAPs = useSelector(selectModalStateForManageCAPs);
  const mostRecentAuditId = useSelector((state) =>
    selectIdOfMostRecentAudit(state, factoryId)
  );

  const handleManageCAPsModalOpen = () => {
    dispatch(getListOfAuditsInCapManagement({ supplierId, factoryId }));
    // dispatch(setModalStateForManageCAPs(true));
    navigate(`/factories/${factoryId}/audit/${mostRecentAuditId}`);
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      p={2}
      border={1}
      borderColor="grey.300"
      borderRadius={2}
      bgcolor="background.paper"
      minHeight={{ xs: "166px", sm: "166px" }}
    >
      <Grid container spacing={2}>
        {/* First row: CAP Status text and Open CAP Management button */}
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box display="flex" alignItems="center">
                <Typography
                  sx={{ color: "#344054", fontSize: "18px", fontWeight: "700" }}
                >
                  CAP Status
                </Typography>
                <Tooltip 
                  title="If the CAP is completed, the status will display 'Completed,' If 4imprint is currently reviewing the latest audit you submitted, it will display 'Audit Under Review' and no further action is needed as of now until 4imprint finishes the review. If there is an ongoing CAP process, click to view the audit details."
                  placement="right"
                >
                  <InfoOutlinedIcon
                    sx={{ marginLeft: "8px", color: "#A0A5AD" }}
                  />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item>
              {status == "Ongoing" && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: "none",
                    borderRadius: "8px",
                    border: "1px solid #2D3282",
                    background: "#2D3282",
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  }}
                  onClick={handleManageCAPsModalOpen}
                >
                  Open CAP Management
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Second row: Full-width Ongoing status box */}
        <Grid item xs={12}>
          {status === "Ongoing" ? (
            <Box
              elevation={2}
              sx={{
                textAlign: "center",
                borderRadius: "6px",
                border: "1px solid rgba(234, 170, 8, 0.30)",
                background: "rgba(234, 170, 8, 0.10)",
                height: "58px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#EAAA08",
                  fontSize: "20px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Ongoing
              </Typography>
            </Box>
          ) : status === "Under Review" ? (
            <Box
              elevation={2}
              sx={{
                textAlign: "center",
                borderRadius: "6px",
                border: "1px solid rgba(102, 112, 133, 0.30)",
                background: "rgba(102, 112, 133, 0.10)",
                height: "58px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#667085",
                  fontSize: "20px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Audit Under Review
              </Typography>
            </Box>
          ) : (
            <Box
              elevation={2}
              sx={{
                textAlign: "center",
                borderRadius: "6px",
                border: "1px solid rgba(34, 197, 94, 0.30)",
                background: "rgba(34, 197, 94, 0.10)",
                height: "58px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#22C55E",
                  fontSize: "20px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Completed
              </Typography>
            </Box>
          )}
          {modalStateForManageCAPs && (
            <CapManagementModal factoryId={factoryId} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FactoryCAPStatus;
