import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuditCAPStatus,
  selectCaseForSecondSubStepper,
  selectCurrentStepInCapManagement,
  selectFirstAuditIssueId,
} from "../../selectors/audit.selector";
import { setSelectedAuditIssueId } from "../../reducers/audit.reducer";
import AuditCapSubProgressStepper from "./auditCapSubProgressStepper.component";

const AuditCapProgressStepper = ({ capDetails = false, auditData }) => {
  const dispatch = useDispatch();
  const firstAuditIssueId = useSelector(selectFirstAuditIssueId);
  const auditCAPStatus = useSelector(selectAuditCAPStatus);
  const handleClick = () => {
    dispatch(setSelectedAuditIssueId(firstAuditIssueId));
  };
  const caseForSecondSubStepper = useSelector(selectCaseForSecondSubStepper);

  const selectedCurrentStepInCapManagement = useSelector(
    selectCurrentStepInCapManagement
  );
  const steps = [
    { label: "4imprint Audit Review" },
    { label: "Supplier CAP Input" },
    { label: "4imprint CAP Review" },
    {
      label: "Supplier Additional Information Needed",
      subLabel:
        "Supplier Additional Information Completed & Requested for Approval",
    },
    { label: "4imprint CAP Closure Review" },
  ];

  const subHeaderText = {
    0: "Audit Review",
    1: "CAP Input",
    2: "CAP Review",
    3: "Additional Information Needed",
    4: "CAP Closure Review",
  };

  const mappingOfCAPStatusToStep = {
    OPEN: 0,
    IN_PROGRESS: 1,
    BRAND_REVIEWING_PLAN: 2,
    BRAND_APPROVED_PLAN: 3,
    SITE_ADDING_PROOF: 3,
    SITE_ADDED_PROOF: 3,
    BRAND_APPROVING_PROOF: 3,
    BRAND_APPROVED_PROOF: 5,
    CLOSED: 5,
  };

  const currentStep = mappingOfCAPStatusToStep[auditCAPStatus];

  const tooltipTexts = {
    0: "Step 1:   Once audits are uploaded, 4imprint will review the findings and assign a criticality level based on its criteria.\n\nNo action is required from the supplier during this step. The supplier will be notified once the review is complete.",
    1: "Step 2: After the audit review, the supplier is required to enter CAP information for each finding, including corrective and preventative actions.",
    2: "Step 3: After the CAP is submitted, 4imprint will review the entries. No action is required from the supplier during this step unless 4imprint reaches out with feedback or suggestions.",
    3: "Step 4: Once 4imprint approves the CAP, the supplier can upload implementation proof (e.g., photos, documents, or text) for each finding and request approval for each submission.",
    4: "Step 5: After the supplier submits CAP proof, 4imprint will review the materials. No further action is required from the supplier during this step.",
  };

  const getStepIcon = (index) => {
    if (index < mappingOfCAPStatusToStep[auditCAPStatus]) {
      return <CheckCircleIcon color="success" />;
    } else if (index === mappingOfCAPStatusToStep[auditCAPStatus]) {
      return (
        <Box position="relative" display="inline-flex">
          <CircleIcon color="primary" />
          <Box
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            top={0}
            left={0}
            bottom={0}
            right={0}
          >
            <Typography
              variant="caption"
              component="div"
              color="white"
              sx={{ zIndex: 100 }}
            >
              {index + 1}
            </Typography>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box position="relative" display="inline-flex">
          <CircleIcon sx={{ color: "#BABABA" }} />
          <Box
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            top={0}
            left={0}
            bottom={0}
            right={0}
          >
            <Typography
              variant="caption"
              component="div"
              color="white"
              sx={{ zIndex: 100 }}
            >
              {index + 1}
            </Typography>
          </Box>
        </Box>
      );
    }
  };

  return !capDetails ? (
    <Box borderRadius={2} p={3} sx={{ backgroundColor: "#F8F8F8", m: 4 }}>
      <Grid container spacing={2} alignItems="center">
        {/* Header for CAP Management Progress */}
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          {auditCAPStatus === "CLOSED" ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#4CAF50",
                width: "100%",
              }}
            >
              <CheckCircleIcon sx={{ mr: 1 }} />
              <Typography sx={{ fontWeight: 600 }}>
                CAP Management Completed
              </Typography>
            </Box>
          ) : (
            <Typography sx={{ fontWeight: "700", fontSize: "16px" }}>
              CAP Management Progress
            </Typography>
          )}
        </Grid>

        {/* Stepper Progress */}
        <Grid item xs={12} sx={{ p: 0, m: 0 }}>
          <Stepper alternativeLabel sx={{ p: 0, m: 0 }}>
            {steps.map((step, index) => (
              <Step
                key={index}
                completed={index < mappingOfCAPStatusToStep[auditCAPStatus]}
                sx={{ p: 0, m: 0 }}
              >
                <StepLabel
                  sx={{ m: 0, p: 0 }}
                  StepIconComponent={() => getStepIcon(index)}
                >
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", fontSize: "14px", mb: 0.5 }}
                    >
                      {index === 0 || index === 2 || index === 4
                        ? "4imprint"
                        : "Supplier"}
                    </Typography>
                    
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "14px", mb: 0.5 }}
                    >
                      {subHeaderText[index]}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 0.5 }}>
                      <Tooltip 
                        title={tooltipTexts[index]}
                        placement="right"
                      >
                        <InfoOutlinedIcon
                          sx={{ color: "#A0A5AD" }}
                        />
                      </Tooltip>
                    </Box>
                    {index === 1 || index === 3 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ textTransform: "none" }}
                        onClick={handleClick}
                        disabled={index !== currentStep}
                      >
                        Enter information
                      </Button>
                    ) : null}
                  </Box>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>

        {/* Current step details */}

        <Grid
          item
          xs={caseForSecondSubStepper?.showSecondSubStepper === true ? 6 : 12}
        >
          {currentStep > 0 && currentStep < 5 && (
            <AuditCapSubProgressStepper
              steps={steps}
              currentStep={currentStep}
              gridItemWidth={
                caseForSecondSubStepper?.showSecondSubStepper === true ? 8 : 4
              }
            />
          )}
        </Grid>
        {caseForSecondSubStepper?.showSecondSubStepper === true && (
          <Grid item xs={6}>
            <AuditCapSubProgressStepper
              steps={steps}
              currentStep={currentStep}
              secondSubStepper={caseForSecondSubStepper}
              gridItemWidth={
                caseForSecondSubStepper?.showSecondSubStepper === true ? 8 : 4
              }
            />
          </Grid>
        )}
      </Grid>
    </Box>
  ) : (
    <Grid container sx={{ my: 1 }}>
      <Grid item xs={12} sx={{ p: 0, m: 0 }}>
        <Stepper alternativeLabel sx={{ p: 0, m: 0 }}>
          {steps.map((step, index) => (
            <Step
              key={index}
              completed={index < mappingOfCAPStatusToStep[auditCAPStatus]}
              sx={{ p: 0, m: 0 }}
            >
              <StepLabel
                sx={{ m: 0, p: 0 }}
                StepIconComponent={() => getStepIcon(index)}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {step.label}
                  <Tooltip 
                    title={tooltipTexts[index]}
                    placement="right"
                  >
                    <InfoOutlinedIcon
                      sx={{ ml: 1, color: "#A0A5AD" }}
                    />
                  </Tooltip>
                </Box>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default AuditCapProgressStepper;
