import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  TextField,
  Button,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectFactoryData } from "../../selectors/factory.selector";

import RiskCard from "../riskCard.component";
import FacilityIssuesCard from "./facilityIssuesCard.component";
import PastCAP from "./factoryActionNeeded.component";
import FactoryActionNeeded from "./factoryActionNeeded.component";
import TwoLineCard from "../twoLineCard.component";
import { ComponentStyles, FacilityStyles } from "../../styles";
import { calculateAuditDueDataData } from "../../utils/lib";
import { selectAuditCAPStatus } from "../../selectors/audit.selector";
import { updateFactoryData } from "../../reducers/factory.reducer";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const FactoryMostRecentAuditDetails = ({ selectedFactory }) => {
  
  const auditCAPStatus = selectedFactory?.latestAudit?.capManagementStatus;
  
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={FacilityStyles.mostRecentAuditHeading}>
          Details from Most Recent Audit
        </Typography>
        <Tooltip 
          title="This section shows a snapshot of the site's performance based on the most recent audit."
          placement="right"
        >
          <InfoOutlinedIcon
            sx={{ marginLeft: "8px", color: "#A0A5AD" }}
          />
        </Tooltip>
      </Box>
      {selectedFactory?.latestAudit?.state !== "active" ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "50px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 16,
              color: "#8B8D97",
            }}
          >
            No audit exists for site status
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={4}>
          {auditCAPStatus === "OPEN" ? (
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: '#8B8D97',
                  fontSize: 16,
                  fontWeight: 600,
                  textAlign: 'center',
                }}
              >
                The latest audit is currently under review by 4imprint.
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid item xs={8}>
                <FacilityIssuesCard latestAuditData={selectedFactory.latestAudit} />
              </Grid>
              <Grid item xs={4}>
                <TwoLineCard
                  title="Audit Score"
                  nextAuditDate={selectedFactory?.latestAudit?.risk?.auditScore || "Coming Soon."}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default FactoryMostRecentAuditDetails;
