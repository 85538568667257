import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  email: null,
  error: null,
  userInfo: {
    userId: null,
    firstName: null,
    lastName: null,
    token: null,
  },
  loggedIn: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    submitLoginRequest: (state, action) => {
      state.email = action.payload?.email || null;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      const payload = action.payload || {};
      const supplierData =
        payload.supplierData && payload.supplierData[0]
          ? payload.supplierData[0]
          : {};
      state.userInfo = {
        ...payload,
        userId: payload._id || null,
        supplierId: supplierData._id || null,
        supplierName: supplierData.name || null,
      };
      state.email = payload.email || state.email;
      state.loggedIn = true;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload || null;
    },
    logout: (state) => {
      localStorage.removeItem("loginState"); // Clear local storage
      state.email = null;
      state.userInfo = {
        userId: null,
        firstName: null,
        lastName: null,
        token: null,
      };
      state.loggedIn = false;
    },
    getOrganisationResources: (state, action) => {},
    setOrganisationResources: (state, action) => {
      state.userInfo.resources = action.payload;
    },
    updateProfileInfo: (state, action) => {},
    updateProfileInfoSuccess: (state, action) => {
      state.userInfo.firstName = action.payload.firstName;
      state.userInfo.lastName = action.payload.lastName;
    },
    updatePassword: (state, action) => {},
    updatePasswordForFirstTime: (state, action) => {},
    updatePasswordForFirstTimeSuccess: (state, action) => {
      state.userInfo.updatedPassword = true;
    },
  },
});
export const {
  submitLoginRequest,
  loginSuccess,
  loginFailure,
  logout,
  getOrganisationResources,
  setOrganisationResources,
  updateProfileInfo,
  updateProfileInfoSuccess,
  updatePassword,
  updatePasswordSuccess,
  updatePasswordForFirstTime,
  updatePasswordForFirstTimeSuccess,
} = loginSlice.actions;

export default loginSlice.reducer;
