import React from "react";
import { Divider, Grid, Box } from "@mui/material";

import AuditReportTabs from "../components/AuditDetails/auditReportTabs.component";
import AuditReportLeftSideNavigationBar from "../components/AuditDetails/auditReportDetailsLeftSideNavigationBar.component";
import AuditReport from "../components/AuditDetails/auditReport.component";
import AuditReportDetailsAppbar from "../components/AuditDetails/auditReportDetailsAppbar.component";
import { useSelector } from "react-redux";
import { selectAuditReportPageTab } from "../selectors/audit.selector";
import AuditReportResources from "../components/AuditDetails/auditReportResources.component";

const AuditReportDetails = ({ handleDrawerClose }) => {
  const auditReportTabValue = useSelector(selectAuditReportPageTab);

  return (
    <Grid container>
      <Box sx={{ width: "100%", position: "sticky", top: 0, zIndex: 1000 }}>
        <AuditReportDetailsAppbar handleDrawerClose={handleDrawerClose} />
      </Box>

      <Grid item xs={2.8}>
        <AuditReportTabs />
        {auditReportTabValue === "issues" ? (
          <AuditReportLeftSideNavigationBar />
        ) : (
          <AuditReportResources />
        )}
      </Grid>
      <Grid item xs={0.2}>
        <Divider orientation="vertical" style={{ margin: "2px 2px" }} />
      </Grid>
      <Grid item xs={9}>
        <AuditReport />
      </Grid>
    </Grid>
  );
};

export default AuditReportDetails;
