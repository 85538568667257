import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Container,
  Divider,
  Button,
  Modal,
  Tooltip,
} from "@mui/material";
import {
  Description,
  Image,
  PictureAsPdf,
  Folder,
  Article,
  TableChart,
  PlayArrow,
} from "@mui/icons-material";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import { FacilityStyles, PageStyles } from "../styles";
import TopBreadcrumbs from "../components/topBreadcrumbs.component";
import { useDispatch, useSelector } from "react-redux";
import {
  selectOrganisationId,
  selectOrganisationResources,
} from "../selectors/login.selector";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import AuditDocumentDrop from "../components/AuditDetails/auditDocumentDrop.component";
import { useParams } from "react-router-dom";
import { getOrganisationResources } from "../reducers/login.reducer";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";

const ResourcesPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const resourcesGroupedByType = useSelector(selectOrganisationResources);

  useEffect(() => {
    dispatch(getOrganisationResources());
  }, []);

  const pinnedResources = Object.values(resourcesGroupedByType)
    .flat()
    .filter((resource) => resource.isPinned);

  const getFileIcon = (type) => {
    switch (type) {
      case "application/pdf":
        return <PictureAsPdf sx={{ fontSize: 50, color: "#e74c3c" }} />;
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <Article sx={{ fontSize: 50, color: "#2b579a" }} />;
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return <TableChart sx={{ fontSize: 50, color: "#217346" }} />;
      case "image/png":
      case "image/jpeg":
      case "image/webp":
        return <Image sx={{ fontSize: 50, color: "#3498db" }} />;
      case "export":
        return <Folder sx={{ fontSize: 50, color: "#f1c40f" }} />;
      case "video/mp4":
      case "video/quicktime":
        return <PlayArrow sx={{ fontSize: 50, color: "#e74c3c" }} />;
      default:
        return <Description sx={{ fontSize: 50, color: "#2ecc71" }} />;
    }
  };
  const renderResourceContent = (resource) => {
    const isVideo =
      resource.type?.startsWith("video") ||
      resource.fileType?.startsWith("video");

    if (isVideo && resource.thumbnailUrl) {
      return (
        <Box sx={{ position: "relative", width: "100%", height: 150 }}>
          <img
            src={resource.thumbnailUrl}
            alt={resource.name || resource.fileName}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
          <PlayArrow
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: 50,
              color: "white",
              backgroundColor: "rgba(0,0,0,0.5)",
              borderRadius: "50%",
              padding: "8px",
            }}
          />
        </Box>
      );
    }

    return (
      <>
        {getFileIcon(resource.type || resource.fileType)}
        <Tooltip title={resource.name || resource.fileName}>
          <Typography
            variant="body1"
            sx={{
              mt: 1,
              fontWeight: 500,
              fontSize: "12px",
              color: "#333",
              width: "100%",
              overflow: "ellipsis",
              textAlign: "center",
              lineHeight: "1.2em",
              height: "2.4em",
            }}
          >
            {(resource.name || resource.fileName || "")
              .replace(/\.[^/.]+$/, "")
              .match(/(.{1,25}(?:\s|$))/g)
              ?.join("\n") ||
              resource.name ||
              resource.fileName ||
              "Untitled"}
          </Typography>
        </Tooltip>
      </>
    );
  };
  return (
    <Box sx={PageStyles.containerBox}>
      <LeftSideNavbar />
      <Box sx={PageStyles.containerBoxExceptNavbar}>
        <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
          <TopBreadcrumbs />
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography gutterBottom sx={PageStyles.name}>
                Resources
              </Typography>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Box>
        <Divider />
        <Container sx={{ py: 4, maxWidth: "100% !important" }}>
          {pinnedResources.length > 0 && (
            <Box sx={{ mb: 4 }}>
              <Card
                sx={{
                  ...FacilityStyles.containerCard,
                  pb: 4,
                  pt: 2,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    my: 1,
                  }}
                >
                  Featured Documents
                </Typography>
                <Grid container spacing={2}>
                  {pinnedResources.map((resource) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={2}
                      key={resource._id}
                    >
                      <Card
                        sx={{
                          position: "relative",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          px: 0.5,
                          width: "100%",
                          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
                          "&:hover": {
                            backgroundColor: "#f9f9f9",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                          },
                          borderRadius: "10px",
                          transition: "all 0.2s ease-in-out",
                        }}
                        elevation={0}
                      >
                        <CardContent sx={{ textAlign: "center", pb: 2 }}>
                          <Box
                            onClick={() =>
                              window.open(
                                resource.fileUrl,
                                "_blank",
                                "noopener,noreferrer"
                              )
                            }
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              textDecoration: "none",
                              color: "inherit",
                              width: "100%",
                            }}
                          >
                            {renderResourceContent(resource)}
                          </Box>
                        </CardContent>

                        {/* <Box
                          sx={{
                            position: "absolute",
                            top: 5,
                            right: 5,
                            zIndex: 1,
                            display: "flex",
                            padding: "12px",
                            cursor: "default",
                            pointerEvents: "none",
                          }}
                        >
                          {resource.isPinned ? (
                            <PushPinIcon
                              sx={{ fontSize: 20, color: "#1976d2" }}
                            />
                          ) : (
                            <PushPinOutlinedIcon
                              sx={{ fontSize: 20, color: "#666" }}
                            />
                          )}
                        </Box> */}
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </Box>
          )}

          {Object.entries(resourcesGroupedByType).map(
            ([type, typeResources], index) => (
              <Box key={`${type}-${index}`} sx={{ mb: 2 }}>
                <Card sx={{ ...FacilityStyles.containerCard, pb: 4, pt: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                      textTransform: "capitalize",
                      my: 1,
                    }}
                  >
                    {type === "Uploaded Files" ? "Miscellaneous" : type}
                  </Typography>
                  <Grid container spacing={2}>
                    {typeResources.map((resource) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={2}
                        key={resource._id}
                      >
                        <Card
                          sx={{
                            position: "relative",
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            px: 1,
                            width: "100%",
                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
                            "&:hover": {
                              backgroundColor: "#f9f9f9",
                              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                            },
                            borderRadius: "10px",
                            transition: "all 0.2s ease-in-out",
                          }}
                          elevation={0}
                        >
                          <CardContent sx={{ textAlign: "center", pb: 2 }}>
                            <Box
                              onClick={() =>
                                window.open(
                                  resource.fileUrl,
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                textDecoration: "none",
                                color: "inherit",
                                width: "100%",
                              }}
                            >
                              {renderResourceContent(resource)}
                            </Box>
                          </CardContent>

                          {/* <Box
                            sx={{
                              position: "absolute",
                              top: 5,
                              right: 5,
                              zIndex: 1,
                              display: "flex",
                              padding: "12px",
                              cursor: "default",
                              pointerEvents: "none",
                            }}
                          >
                            {resource.isPinned ? (
                              <PushPinIcon
                                sx={{ fontSize: 20, color: "#1976d2" }}
                              />
                            ) : (
                              <PushPinOutlinedIcon
                                sx={{ fontSize: 20, color: "#666" }}
                              />
                            )}
                          </Box> */}
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Box>
            )
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default ResourcesPage;
