import React from "react";
import { Routes, Route } from "react-router-dom";

import SupplierDetails from "./supplierDetails.page";
import FacilityDetails from "./facilityDetails.page";
import AuditDetails from "./auditDetails.page";
import ResourcesPage from "./resources.page";

const IndexPage = () => {
  return (
    <Routes>
      <Route path="/suppliers" element={<SupplierDetails />} />
      <Route path=":factoryId" element={<FacilityDetails />} />
      <Route path=":factoryId/audit/:auditId" element={<AuditDetails />} />
      <Route path="/resources" element={<ResourcesPage />} />
    </Routes>
  );
};

export default IndexPage;
