import React from "react";
import { Box, Grid, Typography, Drawer, Divider } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { selectSupplierNameFromId } from "../../selectors/supplier.selector";
import { useSelector } from "react-redux";
import { ComponentStyles, FacilityStyles } from "../../styles";

const FacilityInformationDrawer = ({
  factoryData,
  isDrawerOpen,
  toggleDrawer,
}) => {
  const params = useParams();
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, params.supplierId)
  );

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{ width: 600, padding: 5, paddingLeft: 6, paddingRight: 6 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Box>
          <Typography variant="h4" sx={{ mb: 4, mt: 2 }}>
            More Information
          </Typography>

          <Divider sx={{ mb: 4 }} />

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Alias</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                {factoryData?.metadata?.alias || "-"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={ComponentStyles.fieldName}>
                Associated Addresses
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.associatedAddress || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

export default FacilityInformationDrawer;
