import React, { useCallback, useState, useEffect } from "react";

import {
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Chip,
  Link,
  Box,
  TextField,
  Paper,
  Tooltip,
  Radio,
  RadioGroup,
  FormControlLabel,
  Card,
  Modal,
} from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import UploadIcon from "@mui/icons-material/Upload";
import Button from "@mui/material/Button";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import CustomChipLabel from "./customChipLabel.component";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuditCAPStatus,
  selectAuditData,
  selectAuditIssueDetails,
  selectSelectedAuditIssueId,
} from "../../selectors/audit.selector";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { getValidData } from "../../utils/lib";
import { debounce } from "lodash";
import {
  selectAutoSaveTimer,
  selectSaveStatus,
} from "../../selectors/misc.selector";
import { saveStatus, setSaveStatus } from "../../reducers/misc.reducer";
import { useParams } from "react-router-dom";
import { updateAuditData, updateIssueData } from "../../reducers/audit.reducer";
import { CheckCircleOutline } from "@mui/icons-material";
import { AuditStyles, ComponentStyles } from "../../styles";
import { Select, MenuItem, FormControl } from "@mui/material";
import CAPChangesUI from "./capApprovedUploadImageProof.component";
import CapApprovedUploadImageProof from "./capApprovedUploadImageProof.component";
import AuditReportCapDetailsSection from "./auditReportCapDetailsSection.component";
import AuditDocumentDrop from "./auditDocumentDrop.component";
import {
  selectOrganisationId,
  selectSupplierId,
} from "../../selectors/login.selector";

const RecommendationsList = ({ title, items }) => (
  <Paper elevation={0} sx={{ mb: 2 }}>
    <Grid
      container
      sx={{
        pl: 2,
      }}
    >
      {/* <Grid item xs={1}>
        <LightbulbOutlinedIcon sx={{ pt: 0.5, color: "#667085" }} />
      </Grid> */}
      <Grid item xs={11}>
        <List dense sx={{ p: 0 }}>
          {items.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemText
                primary={
                  <Typography sx={AuditStyles.auditReportFieldValue}>
                    {item}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  </Paper>
);

const AiGeneratedField = ({ label, children }) => (
  <Box>
    <Typography
      sx={{
        color: "#475467",
        fontSize: "16px",
        fontWeight: 700,
        mb: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      {label}
      <Tooltip title="Generated by AI">
        <AutoAwesomeIcon sx={{ fontSize: 16, ml: 0.5, color: "darkgrey" }} />
      </Tooltip>
    </Typography>
    {children}
  </Box>
);
const RootCauseAnalysis = ({ items }) => (
  <Paper elevation={0} sx={{ mb: 2 }}>
    <List dense sx={{ p: 0 }}>
      {items.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemText
            primary={
              <Typography
                sx={{ color: "#000", fontSize: "16px", fontWeight: 400, pl: 1 }}
              >
                {item ===
                'Determine the root cause by using the "5 Whys Methodology", starting with the question below and continuing until you reach an answer from the dropdown list in column L. Refer to the Instructions tab for additional help.'
                  ? 'Determine the root cause by using the "5 Whys Methodology", starting with the question below and continuing until you reach an answer from the dropdown list to the left. Refer to the "CAP Development Guidance" document below for additional help.'
                  : item}
              </Typography>
            }
          />
        </ListItem>
      ))}

      <ListItem>
        <Link
          href="https://drive.google.com/file/d/1HnYbGT4-sc_cU-WlUqr7QTIEyv4hrD7n/view?usp=drive_link"
          target="_blank"
          rel="noopener"
        >
          4imprint CAP Guidance
        </Link>
      </ListItem>
    </List>
  </Paper>
);

const AuditReport = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const data = useSelector(selectAuditIssueDetails);
  const saveStatus = useSelector(selectSaveStatus);
  const [random, setRandom] = useState(0);
  const selectedIssueId = useSelector(selectSelectedAuditIssueId);
  const selectedAuditData = useSelector(selectAuditData);
  const [correctiveActionPlan, setCorrectiveActionPlan] = useState("");
  const [longTermActionPlan, setLongTermActionPlan] = useState("");
  const [rootCauseAnalysis, setRootCauseAnalysis] = useState("");
  const [responsiblePerson, setResponsiblePerson] = useState("");
  const [targetDate, setTargetDate] = useState(null);
  const [
    targetDateForImmediateActionPlan,
    setTargetDateForImmediateActionPlan,
  ] = useState(null);
  const [
    targetDateForLongTermPreventativeAction,
    setTargetDateForLongTermPreventativeAction,
  ] = useState(null);

  const [rootCauseOption, setRootCauseOption] = useState("");
  const auditCapStatus = useSelector(selectAuditCAPStatus);
  const [capStatus, setCapStatus] = useState("no");
  const [open, setOpen] = useState(false);
  const organisationId = useSelector(selectOrganisationId);
  const supplierId = useSelector(selectSupplierId);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setUploading(true);
    // setTimeout(() => {
    //   refreshAuditList();
    //   setUploading(false);
    // }, 5000); // Wait for 5 seconds before refreshing the list
  };
  const debouncedSaveNote = useCallback(
    debounce((newNote) => {
      dispatch(
        updateAuditData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { capDetailsNote: newNote },
          issueId: selectedIssueId,
        })
      );
    }, 1000),
    []
  );

  const debouncedSaveCorrectiveActionPlan = useCallback(
    debounce((newActionPlan, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.immediateCorrectiveAction": newActionPlan },
          issueId,
        })
      );
    }, 1000),
    []
  );

  const debouncedSaveLongTermActionPlan = useCallback(
    debounce((newActionPlan, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: {
            "actionPlan.longTermPreventativeAction": newActionPlan,
          },
          issueId,
        })
      );
    }, 1000),
    []
  );
  const debouncedSaveTargetDate = useCallback(
    debounce((newTargetDate, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.deadline": newTargetDate },
          issueId,
        })
      );
    }, 1000),
    []
  );

  const debouncedSaveTargetDateForImmediateActionPlan = useCallback(
    debounce((newTargetDate, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: {
            "actionPlan.deadlineForImmediateActionPlan": newTargetDate,
          },
          issueId,
        })
      );
    }, 1000),
    []
  );
  const debouncedSaveTargetDateForLongTermPreventativeAction = useCallback(
    debounce((newTargetDate, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: {
            "actionPlan.deadlineForLongTermPreventativeAction": newTargetDate,
          },
          issueId,
        })
      );
    }, 1000),
    []
  );
  const debouncedSaveRootCauseAnalysis = useCallback(
    debounce((newRootCauseAnalysis, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.rootCauseAnalysis": newRootCauseAnalysis },
          issueId,
        })
      );
    }, 1000),
    []
  );
  const debouncedSaveResponsiblePerson = useCallback(
    debounce((newResponsiblePerson, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.responsiblePerson": newResponsiblePerson },
          issueId,
        })
      );
    }, 1000),
    []
  );
  const debouncedSaveRootCauseOption = useCallback(
    debounce((newRootCauseOption, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.rootCause": newRootCauseOption },
          issueId,
        })
      );
    }, 1000),
    []
  );
  const handleCorrectiveActionPlanChange = (event) => {
    const newValue = event.target.value;
    setCorrectiveActionPlan(newValue);

    debouncedSaveCorrectiveActionPlan(newValue, selectedIssueId);
  };

  const handleLongTermActionPlanChange = (event) => {
    const newValue = event.target.value;
    setLongTermActionPlan(newValue);
    debouncedSaveLongTermActionPlan(newValue, selectedIssueId);
  };

  const handleRootCauseAnalysisChange = (event) => {
    const newValue = event.target.value;
    setRootCauseAnalysis(newValue);
    debouncedSaveRootCauseAnalysis(newValue, selectedIssueId);
  };
  const handleResponsiblePersonChange = (event) => {
    const newValue = event.target.value;
    setResponsiblePerson(newValue);
    debouncedSaveResponsiblePerson(newValue, selectedIssueId);
  };
  const handleTargetDateChange = (event) => {
    const newValue = event.target.value;
    setTargetDate(newValue);
    debouncedSaveTargetDate(newValue, selectedIssueId);
  };
  const handleTargetDateForImmediateActionPlanChange = (event) => {
    const newValue = event.target.value;
    setTargetDateForImmediateActionPlan(newValue);
    debouncedSaveTargetDateForImmediateActionPlan(newValue, selectedIssueId);
  };
  const handleTargetDateForLongTermPreventativeActionChange = (event) => {
    const newValue = event.target.value;
    setTargetDateForLongTermPreventativeAction(newValue);
    debouncedSaveTargetDateForLongTermPreventativeAction(
      newValue,
      selectedIssueId
    );
  };
  const handleRootCauseOptionChange = (event) => {
    const newValue = event.target.value;
    setRootCauseOption(newValue);
    debouncedSaveRootCauseOption(newValue, selectedIssueId);
  };

  const handleCapStatusChange = (event) => {
    setCapStatus(event.target.value);
    if (event.target.value === "yes") {
      handleOpen();
    }
    dispatch(
      updateIssueData({
        auditId: params?.auditId,
        supplierId: params?.supplierId,
        factoryId: params?.factoryId,
        changes: {
          "actionPlan.capClosedClaimedBySupplier": event.target.value,
        },
        issueId: selectedIssueId,
      })
    );
  };

  useEffect(() => {
    if (saveStatus === "saved") {
      setTimeout(() => dispatch(setSaveStatus("")), 1000);
    }
  }, [saveStatus]);

  useEffect(() => {
    if (data?.actionPlan) {
      if (data?.actionPlan?.immediateCorrectiveAction) {
        setCorrectiveActionPlan(data?.actionPlan?.immediateCorrectiveAction);
      }
      if (data?.actionPlan?.longTermPreventativeAction) {
        setLongTermActionPlan(data?.actionPlan?.longTermPreventativeAction);
      }
      if (data?.actionPlan?.rootCauseAnalysis) {
        setRootCauseAnalysis(data?.actionPlan?.rootCauseAnalysis);
      }
      if (data?.actionPlan?.responsiblePerson) {
        setResponsiblePerson(data?.actionPlan?.responsiblePerson);
      }
      if (data?.actionPlan?.rootCause) {
        setRootCauseOption(data?.actionPlan?.rootCause);
      }
      if (data?.actionPlan?.deadline) {
        setTargetDate(
          new Date(data?.actionPlan?.deadline).toISOString().split("T")[0]
        );
      } else {
        setTargetDate(null);
      }

      if (data?.actionPlan?.capClosedClaimedBySupplier) {
        setCapStatus("yes");
      } else {
        setCapStatus("no");
      }
    }
    if (data?.actionPlan?.deadlineForImmediateActionPlan) {
      setTargetDateForImmediateActionPlan(
        new Date(data?.actionPlan?.deadlineForImmediateActionPlan)
          .toISOString()
          .split("T")[0]
      );
    } else {
      setTargetDateForImmediateActionPlan(null);
    }
    if (data?.actionPlan?.deadlineForLongTermPreventativeAction) {
      setTargetDateForLongTermPreventativeAction(
        new Date(data?.actionPlan?.deadlineForLongTermPreventativeAction)
          .toISOString()
          .split("T")[0]
      );
    } else {
      setTargetDateForLongTermPreventativeAction(null);
    }
  }, [data]);

  useEffect(() => {
    setCorrectiveActionPlan(data?.actionPlan?.immediateCorrectiveAction || "");
    setLongTermActionPlan(data?.actionPlan?.longTermPreventativeAction || "");
    setRootCauseAnalysis(data?.actionPlan?.rootCauseAnalysis || "");
    setResponsiblePerson(data?.actionPlan?.responsiblePerson || "");

    setTargetDate(
      data?.actionPlan?.deadline
        ? new Date(data?.actionPlan?.deadline).toISOString().split("T")[0]
        : null
    );
    setRootCauseOption(data?.actionPlan?.rootCause || "");
  }, [selectedIssueId]);

  const [note, setNote] = useState("");
  useEffect(() => {
    setNote("");
    setNote(getValidData(data.note));
  }, [selectedIssueId]);

  const handleNoteChange = (event) => {
    const newValue = event.target.value;
    dispatch(setSaveStatus("saving"));
    setNote(newValue);

    debouncedSaveNote(newValue);
  };
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const options = [
    {
      value: "Inadequate Knowledge or Awareness",
      label: "Inadequate Knowledge or Awareness",
    },
    {
      value: "Lack of Management Commitment",
      label: "Lack of Management Commitment",
    },
    {
      value: "Lack of Policies & Procedures",
      label: "Lack of Policies & Procedures",
    },
    {
      value: "poor_implementation_practices",
      label: "Poor Implementation Practices",
    },
    { value: "Environmental Conditions", label: "Environmental Conditions" },
    { value: "Resource Constraints", label: "Resource Constraints" },
    {
      value: "Inefficient Processes & Procedures",
      label: "Inefficient Processes & Procedures",
    },
    { value: "System Malfunctions", label: "System Malfunctions" },
  ];
  useEffect(() => {
    setRandom(Math.floor(Math.random() * 10));
  }, []);

  // Function to calculate Must Completed By Date
  const getMustCompleteByDate = (capStartDate, timeline) => {
    let daysToAdd = 0;
    const timelineLower = timeline?.toLowerCase() || "";

    if (timelineLower === "immediate") {
      daysToAdd = 0;
    } else {
      const match = timelineLower.match(/(\d+)\s*days?/);
      if (match) {
        daysToAdd = parseInt(match[1], 10);
      } else {
        // Handle unexpected timeline formats
        daysToAdd = 0;
      }
    }

    if (!capStartDate) return null;

    const startDate = new Date(capStartDate);
    const mustCompleteByDate = new Date(startDate);
    mustCompleteByDate.setDate(mustCompleteByDate.getDate() + daysToAdd);

    return mustCompleteByDate;
  };

  // Function to format date as mm/dd/yyyy
  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    if (isNaN(d.getTime())) return "";
    const month = "" + (d.getMonth() + 1);
    const day = "" + d.getDate();
    const year = d.getFullYear();

    return [month.padStart(2, "0"), day.padStart(2, "0"), year].join("/");
  };

  // Retrieve capStartDate and timeline from your data
  const capStartDate =
    selectedAuditData?.metadata?.facilityData?.cap_start_date;
  const timeline = data?.timeline;

  // Calculate Must Complete By Date
  const mustCompleteByDate = getMustCompleteByDate(capStartDate, timeline);
  console.log("auditCapStatus", auditCapStatus);
  return (
    <Container sx={{ mt: 4, overflowY: "auto" }}>
      <Box sx={{ p: 2 }}>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 800,
            color: "#667085",
            mb: 1,
          }}
        >
          Finding
        </Typography>
        <Typography
          sx={{
            ...AuditStyles.auditReportFieldValue,
            fontSize: "24px",
            fontWeight: 400,
            mt: 2,
            mb: 3,
          }}
        >
          {data?.issueSummary || "-"}
        </Typography>

        {auditCapStatus === "OPEN" ? (
          <AuditReportCapDetailsSection
            auditCapStatus={auditCapStatus}
            data={data}
          />
        ) : (
          <>
            <Accordion
              disableGutters
              elevation={0}
              square
              sx={{
                padding: "12px",
                borderRadius: "12px",
                border: "1px solid #D0D5DD",
                background: "#FFF",
                boxShadow: "none",
                "&:before": {
                  display: "none",
                },
                "&.Mui-expanded": {
                  margin: 0,
                },
                "& .MuiAccordion-root": {
                  boxShadow: "none",
                  borderRadius: "12px",
                },
                "& .MuiPaper-root": {
                  boxShadow: "none",
                  borderRadius: "12px",
                },
                "& .MuiAccordionSummary-root": {
                  borderRadius: "12px",
                },
                "& .MuiAccordionDetails-root": {
                  borderRadius: "0 0 12px 12px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  minHeight: "48px",
                  "&.Mui-expanded": {
                    minHeight: "48px",
                    borderBottom: "1px solid #E0E0E0",
                    borderRadius: "0px",
                  },
                  "& .MuiAccordionSummary-content": {
                    margin: "12px 0",
                  },
                  "& .MuiAccordionSummary-content.Mui-expanded": {
                    margin: "12px 0",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography sx={{ ...AuditStyles.fieldTitle, mb: 1 }}>
                    Finding Details
                  </Typography>
                  <Typography sx={AuditStyles.auditReportFieldValue}>
                    {data?.issueDetail || "-"}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {/* Details Section */}
                <AuditReportCapDetailsSection
                  auditCapStatus={auditCapStatus}
                  data={data}
                />
              </AccordionDetails>
            </Accordion>
          </>
        )}
        {auditCapStatus === "BRAND_APPROVED_PLAN" ||
        auditCapStatus === "SITE_ADDING_PROOF" ||
        auditCapStatus === "SITE_ADDED_PROOF" ||
        auditCapStatus === "BRAND_APPROVED_PROOF" ||
        auditCapStatus === "BRAND_APPROVING_PROOF" ||
        auditCapStatus === "CLOSED" ||
        data?.status === "BRAND_APPROVED_PROOF" ||
        data?.status === "CLOSED" ? (
          <CapApprovedUploadImageProof
            immediateActions={data?.actionPlan?.immediateCorrectiveAction}
            longTermActions={data?.actionPlan?.longTermPreventativeAction}
            targetDate={data?.actionPlan?.deadline}
            responsiblePerson={data?.actionPlan?.responsiblePerson}
            rootCauseAnalysis={data?.actionPlan?.rootCauseAnalysis}
            status={data?.status}
            capManagementStatus={auditCapStatus}
            rootCause={data?.actionPlan?.rootCause}
            issueId={selectedIssueId}
            proofDocuments={data?.proofDocuments}
            deadlineForImmediateActionPlan={
              data?.actionPlan?.deadlineForImmediateActionPlan
            }
            deadlineForLongTermPreventativeAction={
              data?.actionPlan?.deadlineForLongTermPreventativeAction
            }
            ciDueDate={data?.ciDueDate}
          />
        ) : auditCapStatus !== "OPEN" ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "20px",
                    fontWeight: 700,
                    textDecorationLine: "underline",
                    mb: 2,
                    mt: 3,
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    hyphens: "auto",
                  }}
                >
                  Site Input
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "20px",
                    fontWeight: 700,
                    textDecorationLine: "underline",
                    mb: 2,
                    mt: 3,

                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    hyphens: "auto",
                  }}
                ></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Responsible Person *
                  <Tooltip
                    title="The person from the site who will be responsible for investigating the root cause and implementating the CAP."
                    placement="right"
                  >
                    <InfoOutlinedIcon
                      sx={{
                        marginLeft: "8px",
                        color: "#A0A5AD",
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder=""
                  value={responsiblePerson}
                  onChange={handleResponsiblePersonChange}
                  required={true}
                  disabled={data?.status === "BRAND_APPROVED_PLAN"}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      fontSize: "16px",
                      "& fieldset": {
                        borderColor: "#D0D5DD",
                      },
                      "&:hover fieldset": {
                        borderColor: "#B2B7C2",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#6941C6",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 14px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  Root Cause Analysis *
                  <Tooltip
                    title="The root cause is the main reason behind the finding and it must be identified in order to determine why the finding was a violation and why it occurred."
                    placement="right"
                  >
                    <InfoOutlinedIcon
                      sx={{
                        marginLeft: "8px",
                        color: "#A0A5AD",
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Select
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid #D0D5DD",
                      background: "#FFF",
                      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                      // padding: "10px 14px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    value={rootCauseOption}
                    onChange={handleRootCauseOptionChange}
                    disabled={data?.status === "BRAND_APPROVED_PLAN"}
                    displayEmpty
                    renderValue={(selected) => selected || "Select an option"}
                  >
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={7}></Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  placeholder="Please explain why you chose the answer above..."
                  value={rootCauseAnalysis}
                  onChange={handleRootCauseAnalysisChange}
                  sx={{ mb: 2 }}
                  required={true}
                  disabled={data?.status === "BRAND_APPROVED_PLAN"}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  sx={{
                    color: "#000",
                    fontSize: "16px",
                    fontWeight: 400,
                    pl: 1,
                  }}
                >
                  If you need help determining a finding's root cause, please
                  refer to:
                </Typography>

                <Typography
                  sx={{
                    color: "#000",
                    fontSize: "16px",
                    fontWeight: 400,
                    pl: 1,
                    cursor: "pointer",
                    textDecoration: "underline",
                    "&:hover": {
                      color: "#666",
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "https://storage.googleapis.com/test_mvp_public/4imprint%20CAP%20Guidance.pdf",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  CAP Development Guidance
                </Typography>

                {/* onClick={() =>
                                window.open(
                                  resource.fileUrl,
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              } */}

                {/* <RootCauseAnalysis
                  items={
                    data?.rootCause ? String(data.rootCause).split("\n") : []
                  }
                /> */}
              </Grid>

              <Grid item xs={12}>
                <Typography fontSize="16px" fontWeight={700}>
                  Immediate Corrective Action Plan *
                  <Tooltip
                    title="An action taken to resolve the finding immediately."
                    placement="right"
                  >
                    <InfoOutlinedIcon
                      sx={{
                        marginLeft: "8px",
                        color: "#A0A5AD",
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  mb: 10,
                }}
              >
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  placeholder="Enter immediate corrective action plan..."
                  value={correctiveActionPlan}
                  onChange={handleCorrectiveActionPlanChange}
                  sx={{ mb: 2 }}
                  required={true}
                  disabled={data?.status === "BRAND_APPROVED_PLAN"}
                />

                <Typography
                  sx={{
                    ...AuditStyles.fieldTitle,
                    mb: 1,
                  }}
                >
                  Target Completion Date For Immediate Corrective Action Plan *
                </Typography>
                <Typography
                  color="primary"
                  sx={{
                    mt: 2,
                  }}
                >
                  Action Must Be Completed By:{" "}
                  {mustCompleteByDate ? formatDate(mustCompleteByDate) : "-"}
                </Typography>
                <TextField
                  type="date"
                  fullWidth
                  value={targetDateForImmediateActionPlan || ""}
                  onChange={handleTargetDateForImmediateActionPlanChange}
                  disabled={data?.status === "BRAND_APPROVED_PLAN"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  Optional Thought Starters: Immediate Corrective Action Plan
                </Typography>
                <RecommendationsList
                  title=""
                  items={
                    data?.capRecommendations?.immediateCAP
                      ? String(data.capRecommendations.immediateCAP).split("\n")
                      : []
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Typography fontSize="16px" fontWeight={700}>
                  Long Term Preventative Action Plan *
                  <Tooltip
                    title="Action(s) taken to ensure that the finding will not occur in the future."
                    placement="right"
                  >
                    <InfoOutlinedIcon
                      sx={{
                        marginLeft: "8px",
                        color: "#A0A5AD",
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  placeholder="Enter long term preventative action plan..."
                  value={longTermActionPlan}
                  onChange={handleLongTermActionPlanChange}
                  sx={{ mb: 2 }}
                  required={true}
                  disabled={data?.status === "BRAND_APPROVED_PLAN"}
                />
                <Typography
                  sx={{
                    ...AuditStyles.fieldTitle,
                    mb: 1,
                  }}
                >
                  Target Completion Date For Long Term Preventative Action Plan
                  *
                </Typography>
                <TextField
                  type="date"
                  fullWidth
                  value={targetDateForLongTermPreventativeAction || ""}
                  onChange={handleTargetDateForLongTermPreventativeActionChange}
                  disabled={data?.status === "BRAND_APPROVED_PLAN"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  Optional Thought Starters: Long Term Preventative Action Plan
                </Typography>
                <RecommendationsList
                  title=""
                  items={
                    data?.capRecommendations?.longTermPreventativeAction
                      ? String(
                          data.capRecommendations.longTermPreventativeAction
                        ).split("\n")
                      : []
                  }
                />
              </Grid>
              <Grid item xs={6} sx={{ mt: 2, mb: 10 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <Typography
                    fontSize="20px"
                    fontWeight={700}
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    CAP Closed? *
                    <Tooltip
                      title="If this CAP is already closed, you can upload CAP implementation proof. If 'Yes' is chosen, proof upload is required"
                      placement="right"
                    >
                      <InfoOutlinedIcon
                        sx={{
                          marginLeft: "8px",
                          color: "#A0A5AD",
                          verticalAlign: "middle",
                        }}
                      />
                    </Tooltip>
                  </Typography>
                  <Box>
                    <RadioGroup
                      value={capStatus}
                      onChange={handleCapStatusChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Box>
                  <Grid item xs={6}>
                    {capStatus === "yes" && (
                      <Button
                        variant="outlined"
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#344054",
                          borderRadius: "8px",
                          padding: "10px 14px",
                          border: "1px solid var(--Gray-3000, #D0D5DD)",
                          background: "var(--Base-White, #FFF)",
                          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                          justifyContent: "center",
                          alignItems: "center",
                          textTransform: "none",
                        }}
                        onClick={handleOpen}
                        startIcon={<UploadFileIcon sx={{ color: "#667085" }} />}
                      >
                        Upload Proof *
                      </Button>
                    )}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6}></Grid>
              {data?.actionPlan?.capClosedClaimedBySupplier && (
                <Grid item xs={6}>
                  <Box
                    sx={{
                      padding: "12px",
                      borderRadius: "12px",
                      background: "#b6b6b614",
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        pl: 1,
                      }}
                    >
                      Uploaded Files:{" "}
                    </Typography>
                    <List sx={{ p: 0 }}>
                      {data?.proofDocuments?.map((proof, index) => (
                        <ListItem key={index} sx={{ p: 1 }}>
                          <Link
                            sx={{
                              fontWeight: 500,
                              fontSize: 16,
                              color: "#45464E",
                              textDecoration: "underline",
                              textDecorationColor: "#45464E",
                              "&:hover": {
                                textDecorationColor: "rgba(69, 70, 78, 0.8)",
                              },
                            }}
                            href={proof.url}
                            target="_blank"
                          >
                            {proof.name}
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Card
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                p: 2,
                mt: 2,
                mb: 2,
                backgroundColor: "#F9FAFB",
                border: "1px solid #EAECF0",
                boxShadow: "none",
              }}
            >
              <InfoOutlinedIcon sx={{ color: "#667085" }} />
              <Typography sx={{ color: "#475467", fontSize: "14px" }}>
                All fields above are required, and input is auto-saved. After
                completing the information for one finding, move to the next.
                Once the CAP details for every finding are entered, click
                "Request CAP Approval" to notify 4imprint for review.
                <br />
                <br />
                Note the "Request CAP Approval" button is disabled if not all
                fields for all finding are filled.
              </Typography>
            </Card>
            <Modal open={open} onClose={handleClose}>
              <Card
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 1000,
                  p: 4,
                  boxShadow:
                    "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
                  border: "1px solid rgba(234, 236, 240, 1)",
                  borderRadius: "12px",
                  py: 2,
                }}
              >
                <AuditDocumentDrop
                  factoryId={params.factoryId}
                  onClose={handleClose}
                  mode="upload"
                  label="Upload CAP Closure Proof here"
                />
              </Card>
            </Modal>
          </>
        ) : (
          <></>
        )}
        {/* <Typography
          sx={{
            color: "#000",
            textAlign: "right",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
          }}
        >
          Last Updated by AuditSense AI
        </Typography> */}
      </Box>
    </Container>
  );
};

export default AuditReport;
