import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Modal,
  Card,
  Grid,
  Paper,
  CircularProgress,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BulletIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectEmail, selectSupplierId } from "../../selectors/login.selector";
import { checkInternalEmail } from "../../utils/lib";
import {
  getListOfAllAudits,
  setAdditionalContextForProof,
  updateIssueData,
} from "../../reducers/audit.reducer";
import AuditDocumentDrop from "../AuditDetails/auditDocumentDrop.component";
import AuditCapDetailsStatusUploadedProof from "./AuditCapDetailsStatusUploadedProof.component";
import { AuditStyles } from "../../styles";

import { debounce } from "lodash";
const RecommendationsList = ({ title, items, site }) => (
  <Paper
    elevation={0}
    sx={{
      mb: 2,
      backgroundColor: site ? "#F9FAFB" : "#FFF",
      padding: site ? "4px 8px" : "0px",
      borderRadius: site ? "12px" : "0px",
    }}
  >
    <Typography
      sx={{
        color: "#475467",
        fontSize: "16px",
        fontWeight: 700,
      }}
    >
      {title}
    </Typography>
    <List dense>
      {items.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemText
            primary={
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {item}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  </Paper>
);
const CapApprovedUploadImageProof = ({
  immediateActions,
  longTermActions,
  status,
  rootCauseAnalysis,
  responsiblePerson,
  targetDate,
  rootCause,
  issueId,
  proofDocuments,
  capManagementStatus,
  deadlineForImmediateActionPlan,
  deadlineForLongTermPreventativeAction,
  ciDueDate,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);

  const supplierId = useSelector(selectSupplierId);
  const userEmail = useSelector(selectEmail);
  const isInternalUser = checkInternalEmail(userEmail);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setUploading(true);
    setTimeout(() => {
      refreshAuditList();
      setUploading(false);
    }, 5000); // Wait for 5 seconds before refreshing the list
  };
  const refreshAuditList = () => {
    dispatch(
      getListOfAllAudits({
        limit: 99,
        offset: 0,
        supplierId: supplierId,
      })
    );
  };

  const [uploadedFile, setUploadedFile] = useState(null);

  // Handler to simulate file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
    }
  };

  const handleRequestProofApproval = () => {
    dispatch(
      updateIssueData({
        auditId: params?.auditId,
        changes: { status: "SITE_ADDED_PROOF" },
        issueId,
      })
    );
  };

  const [additionalContext, setAdditionalContext] = useState(
    proofDocuments?.[0]?.description || ""
  );
  const debouncedSaveAdditionalContext = useCallback(
    debounce((newAdditionalContext, issueId) => {
      dispatch(
        setAdditionalContextForProof({
          auditId: params.auditId,
          issueId: issueId,
          additionalContext: newAdditionalContext,
        })
      );
    }, 1000),
    []
  );
  const handleAdditionalContextChange = (event) => {
    const newValue = event.target.value;
    setAdditionalContext(newValue);
    debouncedSaveAdditionalContext(newValue, issueId);
  };

  useEffect(() => {
    setAdditionalContext(proofDocuments?.[0]?.description || "");
  }, [proofDocuments]);

  // Add new state to track if we have either proof
  const hasValidProof =
    proofDocuments?.length > 0 || additionalContext?.trim().length > 0;

  return (
    <Box sx={{ mt: 5, mb: 3 }}>
      {/* Title */}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "#4CAF50",
          mt: 5,
          mb: 2,
        }}
      >
        <CheckCircleIcon sx={{ mr: 1 }} />
        <Typography
          sx={{ fontWeight: "600", fontSize: "24px", color: "#4CAF50" }}
        >
          {status === "CONTINUOUS_IMPROVEMENT"
            ? "Continuous Improvement"
            : "Approved CAP"}
        </Typography>
      </Box>
      {status === "CONTINUOUS_IMPROVEMENT" && (
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            backgroundColor: "#FFFFE0",
            color: "#4CAF50",
            px: 1,
            py: 0.5,
            borderRadius: "12px",
          }}
        >
          <span style={{ fontWeight: "600" }}>CI Due Date: </span>
          {ciDueDate
            ? String(moment(ciDueDate).format("MMM. DD, YYYY")).split("\n")
            : []}
        </Typography>
      )}

      <Divider sx={{ mb: 3 }} />
      <Grid container sx={{ mb: 3 }} spacing={2}>
        <Grid item xs={4}>
          <Typography
            sx={{
              mb: 1,
              fontWeight: "700",
              fontSize: "16px",
              color: "#475467",
            }}
          >
            Root Cause
          </Typography>
          <RecommendationsList
            title=""
            items={rootCause ? String(rootCause).split("\n") : []}
            site={true}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography
            sx={{
              mb: 1,
              fontWeight: "700",
              fontSize: "16px",
              color: "#475467",
            }}
          >
            Root Cause Analysis
          </Typography>
          <RecommendationsList
            title=""
            items={
              rootCauseAnalysis ? String(rootCauseAnalysis).split("\n") : []
            }
            site={true}
          />
        </Grid>
      </Grid>
      {/* Immediate Corrective Action */}
      <Typography
        sx={{ mb: 1, fontWeight: "700", fontSize: "16px", color: "#475467" }}
      >
        Immediate Corrective Action
      </Typography>

      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 400,
          backgroundColor: "#FFFFE0",
          px: 1,
          py: 0.5,
          borderRadius: "12px",
        }}
      >
        <span style={{ fontWeight: "600" }}>Target Completion Date: </span>
        {deadlineForImmediateActionPlan
          ? String(
              moment(deadlineForImmediateActionPlan).format("MMM. DD, YYYY")
            ).split("\n")
          : []}
      </Typography>
      <RecommendationsList
        title=""
        items={immediateActions ? String(immediateActions).split("\n") : []}
        site={true}
      />

      {/* <Typography variant="body1" sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
        Changes suggested: {immediateActions[0]}
      </Typography> */}

      {/* Long Term Preventative Action */}
      <Typography
        sx={{
          mb: 1,
          fontWeight: "700",
          fontSize: "16px",
          color: "#475467",
          mt: 3,
        }}
      >
        Long Term Preventative Action
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 400,
          backgroundColor: "#FFFFE0",
          px: 1,
          py: 0.5,
          borderRadius: "12px",
        }}
      >
        <span style={{ fontWeight: "600" }}>Target Completion Date: </span>
        {deadlineForLongTermPreventativeAction
          ? String(
              moment(deadlineForLongTermPreventativeAction).format(
                "MMM. DD, YYYY"
              )
            ).split("\n")
          : []}
      </Typography>
      <RecommendationsList
        title=""
        items={longTermActions ? String(longTermActions).split("\n") : []}
        site={true}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              mb: 1,
              fontWeight: "700",
              fontSize: "16px",
              color: "#475467",
              mt: 3,
            }}
          >
            Responsible Person
          </Typography>
          <RecommendationsList
            title=""
            items={
              responsiblePerson ? String(responsiblePerson).split("\n") : []
            }
            site={true}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <Typography
            sx={{
              mb: 1,
              fontWeight: "700",
              fontSize: "16px",
              color: "#475467",
              mt: 3,
            }}
          >
            Target Date
          </Typography>

          <RecommendationsList
            title=""
            items={
              targetDate
                ? String(moment(targetDate).format("MMM. DD, YYYY")).split("\n")
                : []
            }
            site={true}
          />
        </Grid> */}
      </Grid>
      {/* Upload Proof Section */}
      {status === "SITE_ADDING_PROOF" || status === "BRAND_APPROVED_PLAN" ? (
        <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
          <Typography
            sx={{
              mt: 2,
              mb: 2,
              fontWeight: "400",
              fontSize: "24px",
              color: "#475467",
            }}
          >
            Upload Proof
          </Typography>
          <Tooltip
            title="To request approval, you can either 1) upload a file as proof or 2) enter text below as proof. Only one of the options is required. Once you complete either option, the 'Request Proof Approval' button will become available."
            placement="right"
          >
            <InfoOutlinedIcon
              sx={{
                marginLeft: "8px",
                color: "#A0A5AD",
                verticalAlign: "middle",
              }}
            />
          </Tooltip>
        </Box>
      ) : status === "SITE_ADDED_PROOF" ? (
        <Typography
          sx={{
            mt: 5,
            mb: 2,
            fontWeight: "400",
            fontSize: "24px",
            color: "#475467",
          }}
        >
          Uploaded Proof Under Review
        </Typography>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#4CAF50",
            mt: 5,
            mb: 2,
          }}
        >
          <CheckCircleIcon sx={{ mr: 1 }} />
          <Typography sx={{ fontWeight: 600, fontSize: "24px" }}>
            {status === "CONTINUOUS_IMPROVEMENT"
              ? "Continuous Improvement"
              : "Approved Proof"}
          </Typography>
        </Box>
      )}

      <Divider sx={{ mb: 2 }} />

      {/* File Upload Button */}
      {status !== "CLOSED" && (
        <Grid container justifyContent="space-between">
          <Grid item>
            {status !== "CLOSED" && (
              <Button
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#344054",
                  borderRadius: "8px",
                  padding: "10px 14px",
                  border: "1px solid var(--Gray-3000, #D0D5DD)",
                  background: "var(--Base-White, #FFF)",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  justifyContent: "center",
                  alignItems: "center",
                  textTransform: "none",
                }}
                disabled={
                  status === "BRAND_APPROVED_PROOF" ||
                  status === "CONTINUOUS_IMPROVEMENT"
                }
                onClick={handleOpen}
                startIcon={<UploadFileIcon />}
              >
                Upload Proof
                {!additionalContext?.trim() && (
                  <Typography
                    component="span"
                    sx={{ ml: 0.5, fontSize: "12px", color: "#667085" }}
                  >
                    (or add text below)
                  </Typography>
                )}
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleRequestProofApproval}
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: "white",
                borderRadius: "8px",
                padding: "10px 14px",
                border: "1px solid var(--Gray-3000, #D0D5DD)",
                background: "#3F51B5",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                justifyContent: "center",
                alignItems: "center",
                textTransform: "none",
              }}
              disabled={
                !hasValidProof ||
                status === "SITE_ADDED_PROOF" ||
                status === "BRAND_APPROVING_PROOF" ||
                status === "BRAND_APPROVED_PROOF" ||
                status === "CONTINUOUS_IMPROVEMENT"
              }
            >
              {status === "SITE_ADDED_PROOF"
                ? "Requested for Approval"
                : "Request Proof Approval"}
            </Button>
          </Grid>
        </Grid>
      )}

      <AuditCapDetailsStatusUploadedProof status={status} />
      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            p: 4,
            boxShadow:
              "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
            border: "1px solid rgba(234, 236, 240, 1)",
            borderRadius: "12px",
            py: 2,
          }}
        >
          <AuditDocumentDrop
            factoryId={params.factoryId}
            onClose={handleClose}
            mode="upload"
          />
        </Card>
      </Modal>

      {/* Display the uploaded file name */}
      {uploadedFile && (
        <Typography variant="body2" sx={{ mt: 2 }}>
          Uploaded file: {uploadedFile.name}
        </Typography>
      )}

      {status !== "BRAND_APPROVED_PROOF" &&
        status !== "CLOSED" &&
        status !== "CONTINUOUS_IMPROVEMENT" && (
          <TextField
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            placeholder={
              proofDocuments?.length > 0
                ? "Optional: Provide any additional context..."
                : "Please provide proof details or upload a document above..."
            }
            value={additionalContext}
            onChange={handleAdditionalContextChange}
            sx={{ my: 2 }}
            required={proofDocuments?.length === 0}
          />
        )}
    </Box>
  );
};

export default CapApprovedUploadImageProof;
