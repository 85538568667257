import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  Tooltip,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { selectAdditionalFactoryInformation } from "../../selectors/factory.selector";
import { useSelector } from "react-redux";
import { FacilityStyles } from "../../styles";
const AdditionalFactoryInformation = () => {

  const dataMigrationMode = false
  const [expanded, setExpanded] = React.useState(true);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const selectedAdditionalFactoryInformation = useSelector(
    selectAdditionalFactoryInformation
  );
  return (
    <Card variant="outlined" sx={FacilityStyles.additionalInformationCard}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" fontWeight="bold">
              Additional Site Information
            </Typography>
            <Tooltip 
              title="Site's details in this section are based on recent audit reports. To request updates or report inaccuracy, click the 'Send 4imprint a note' button in the top-right corner."
              placement="right"
            >
              <InfoOutlinedIcon
                sx={{ marginLeft: "8px", color: "#A0A5AD" }}
              />
            </Tooltip>
          </Box>
          <Button onClick={handleToggleExpand} size="small">
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        </Box>
        {expanded && (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {dataMigrationMode ? (
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Data Migration and cleaning in progress. This section is temporarily disabled.
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs={3}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ display: "inline-flex" }}
                    gap={2}
                  >
                    {selectedAdditionalFactoryInformation.map((section, index) => (
                      <Box
                        key={index}
                        variant="outlined"
                        sx={FacilityStyles.additionalInformationSectionHeading}
                      >
                        {section.heading}
                      </Box>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Grid container spacing={2}>
                    {selectedAdditionalFactoryInformation.map(
                      (section, sectionIndex) =>
                        section.values.map((item, itemIndex) => (
                          <Grid item xs={3} key={`${sectionIndex}-${itemIndex}`}>
                            <Typography variant="body2" color="textSecondary">
                              {item.label}
                            </Typography>
                            <Typography variant="body2" sx={{ fontWeight: 500 }}>
                              {item.value}
                            </Typography>
                          </Grid>
                        ))
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default AdditionalFactoryInformation;
