import React, { useEffect } from "react";
import { Grid, Typography, Box, Divider, Card, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSupplierNameFromId,
  selectSupplierPageTab,
} from "../selectors/supplier.selector";

import TopBreadcrumbs from "../components/topBreadcrumbs.component";

import SupplierInformation from "../components/SupplierDetails/supplierInformation.component";
import LeftSideNavbar from "../components/leftSideNavbar.component";

import SupplierSupplyChainOverview from "../components/SupplierDetails/supplierSupplyChainOverview.component";
import { selectSupplierData } from "../selectors/supplier.selector";
import { PageStyles } from "../styles";
import {
  getSupplierById,
  setSupplierDataFromLogin,
} from "../reducers/supplier.reducer";
import {
  selectSupplierDataFromLogin,
  selectSupplierId,
} from "../selectors/login.selector";
import { getListOfAuditsInCapManagement } from "../reducers/audit.reducer";
import { setNoteModalStatus } from "../reducers/misc.reducer";
import SendNote from "../components/sendNote.component";

const SupplierDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const supplierId = useSelector(selectSupplierId);
  const supplierData = useSelector(selectSupplierData);

  const supplierDataFromLogin = useSelector(selectSupplierDataFromLogin);

  useEffect(() => {
    if (!supplierData && supplierId)
      dispatch(setSupplierDataFromLogin(supplierDataFromLogin));
  }, []);
  useEffect(() => {
    dispatch(getSupplierById(supplierId));
    dispatch(getListOfAuditsInCapManagement({ supplierId }));
  }, [dispatch, supplierId]);
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, supplierId)
  );

  return (
    <Box sx={PageStyles.containerBox}>
      <LeftSideNavbar />
      <Box sx={PageStyles.containerBoxExceptNavbar}>
        <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
          <TopBreadcrumbs />
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography gutterBottom sx={PageStyles.name}>
                {supplierData?.name || supplierName || "-"}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  backgroundColor: "#2D3282",
                  borderRadius: "8px",

                  padding: "8px 12px",
                  fontSize: "14px",
                  fontWeight: "600",
                  mr: 0,
                }}
                onClick={() => dispatch(setNoteModalStatus(true))}
              >
                Send 4imprint a note
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider />

        <Grid container spacing={2} sx={PageStyles.secondSectionGrid}>
          <Grid item xs={12} md={12}>
            <SupplierInformation />
          </Grid>

          <Grid item xs={12}>
            <Card sx={PageStyles.cardForTabsAndBelow}>
              <Grid container>
                <Grid item xs={12}>
                  <SupplierSupplyChainOverview />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <SendNote type={1} />
    </Box>
  );
};

export default SupplierDetails;
