import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider, CssBaseline } from "@mui/material";

import Login from "./components/login.component";
import IndexPage from "./pages/index.page";
import theme from "./utils/theme";
import { selectLoggedIn, selectProfileInfo } from "./selectors/login.selector";
import { loginSuccess } from "./reducers/login.reducer";
import Factories from "./pages/factory.page";
import RequiredPasswordUpdate from "./components/requiredPasswordUpdate.component";
import { ToastContainer } from "react-toastify";
function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectLoggedIn);
  const userInfo = useSelector(selectProfileInfo);
  useEffect(() => {
    const storedState = localStorage.getItem("loginState");
    if (storedState) {
      const state = JSON.parse(storedState);
      if (state && state._id && state.token) {
        dispatch(loginSuccess(state));
      }
    }
  }, [dispatch]);

  return (
    <>
      <ToastContainer position="bottom-left" theme="dark" />

      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Router>
            <Routes>
              <Route
                path="/factories"
                element={!isLoggedIn ? <Login /> : <Factories />}
              />
              <Route
                path="/factories/*"
                element={!isLoggedIn ? <Login /> : <IndexPage />}
              />
              <Route
                path="/"
                element={
                  !isLoggedIn ? <Login /> : <Navigate to="/suppliers" replace />
                }
              />

              <Route
                path="*"
                element={
                  isLoggedIn && !userInfo.updatedPassword ? (
                    <RequiredPasswordUpdate />
                  ) : isLoggedIn ? (
                    <IndexPage />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
            </Routes>
          </Router>
        </CssBaseline>
      </ThemeProvider>
    </>
  );
}

export default App;
