// src/components/Login.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  submitLoginRequest,
  updatePassword,
  updatePasswordForFirstTime,
  updateProfileInfo,
} from "../reducers/login.reducer";
import {
  Button,
  TextField,
  Typography,
  Paper,
  Box,
  Modal,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  selectLoggedIn,
  selectLoginError,
  selectProfileInfo,
} from "../selectors/login.selector";
import { selectSettingsModalStatus } from "../selectors/misc.selector";
import { setSettingsModalStatus } from "../reducers/misc.reducer";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const RequiredPasswordUpdate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectProfileInfo);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    if (user) {
      if (user.email) {
        setEmail(user.email);
      }
      if (user.firstName) {
        setFirstName(user.firstName);
      }
      if (user.lastName) {
        setLastName(user.lastName);
      }
    }
  }, [user]);
  const [emailRequiredError, setEmailRequiredError] = useState(null);
  const [passwordRequiredError, setPasswordRequiredError] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [originalPassword, setOriginalPassword] = useState("");
  const [error, setError] = useState("");
  const [showPasswords, setShowPasswords] = useState({
    original: false,
    new: false,
    confirm: false,
  });

  const handleTogglePassword = (field) => {
    setShowPasswords((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      firstName.trim() !== user.firstName.trim() ||
      lastName.trim() !== user.lastName.trim()
    ) {
      dispatch(updateProfileInfo({ firstName, lastName }));
    }
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    setError("");
    dispatch(
      updatePasswordForFirstTime({
        newPassword,
      })
    );
  };

  const onCancel = () => {
    setShowPasswordReset(false);
    setError("");
    setOriginalPassword("");
    setNewPassword("");
    setConfirmPassword("");
    dispatch(logout());
  };

  const loggedIn = useSelector(selectLoggedIn);

  useEffect(() => {
    if (loggedIn) {
      navigate("/suppliers");
    }
  }, [loggedIn]);
  const handleDrawerClose = () => {
    dispatch(setSettingsModalStatus(false));
  };
  const settingsModalStatus = useSelector(selectSettingsModalStatus);
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  console.log("user", email, firstName, lastName);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "99vh",
        fontFamily: "Inter",
        boxShadow:
          "0px 3px 3px -2px rgb(0 0 0 / 0%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 0%)",
      }}
    >
      <Paper
        elevation={10}
        sx={{
          borderRadius: "12px",
          gap: 3,
          padding: "32px 40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: 400,
          height: 540,
          boxShadow:
            "0px 6px 6px -3px rgb(0 0 0 / 0%), 0px 10px 14px 1px rgb(0 0 0 / 6%), 0px 4px 18px 3px rgb(0 0 0 / 1%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            py: 2,
            px: 1,
          }}
        >
          <img src="/logo.svg" alt="Logo" style={{ width: 52, height: 49 }} />
        </Box>

        <Typography
          style={{
            fontWeight: 600,
            fontSize: 24,
            lineHeight: "32px",
            color: "#101828",
          }}
        >
          AuditSense AI
        </Typography>
        <form onSubmit={handlePasswordReset}>
          <TextField
            label="New Password"
            type={showPasswords.new ? "text" : "password"}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{
              mt: 3,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "8px", // Set the border-radius here
                },
              },
              "& .MuiOutlinedInput-input": {
                height: "40px", // Control the height of the input
                padding: "0 14px", // Adjust padding as needed
              },
              "& .MuiInputLabel-outlined": {
                lineHeight: "40px", // Align the label height with the input height
                transform: "translate(14px, 0px) scale(1)", // Adjust label position
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -14px) scale(0.75)", // Adjust label position when focused
              },
            }}
            InputProps={{
              style: {
                fontFamily: "Inter, sans-serif", // Set the font family for the input text
                alignItems: "center",
                fontSize: "14px",

                // Center align the text vertically
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleTogglePassword("new")}
                    edge="end"
                  >
                    {showPasswords.new ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px",
                maring: 0,
                padding: 0,
              },
            }}
          />
          <TextField
            label="Confirm Password"
            type={showPasswords.confirm ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{
              mt: 3,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "8px", // Set the border-radius here
                },
              },
              "& .MuiOutlinedInput-input": {
                height: "40px", // Control the height of the input
                padding: "0 14px", // Adjust padding as needed
              },
              "& .MuiInputLabel-outlined": {
                lineHeight: "40px", // Align the label height with the input height
                transform: "translate(14px, 0px) scale(1)", // Adjust label position
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -14px) scale(0.75)", // Adjust label position when focused
              },
            }}
            InputProps={{
              style: {
                fontFamily: "Inter, sans-serif", // Set the font family for the input text
                alignItems: "center",
                fontSize: "14px",

                // Center align the text vertically
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleTogglePassword("confirm")}
                    edge="end"
                  >
                    {showPasswords.confirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px",
                maring: 0,
                padding: 0,
              },
            }}
          />
          <Box sx={{ mt: 6, mb: 2, display: "flex", gap: 2 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "#3538CD",
                borderRadius: "8px",
                textTransform: "none",
                padding: "10px 16px",
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              Update Password
            </Button>
            <Button
              onClick={onCancel}
              fullWidth
              variant="outlined"
              sx={{
                borderRadius: "8px",
                textTransform: "none",
                padding: "10px 16px",
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              Cancel
            </Button>
          </Box>
          {error && (
            <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
              {error}
            </Typography>
          )}
        </form>
      </Paper>
    </Box>
  );
};

export default RequiredPasswordUpdate;
