import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  CircularProgress,
  IconButton,
  Divider,
  Tooltip
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { selectFactoryData } from "../../selectors/factory.selector";
import { selectSupplierNameFromId } from "../../selectors/supplier.selector";
import { selectEditModeState } from "../../selectors/misc.selector";

import FacilityInformationDrawer from "./facilityInformationDrawer.component";

import { getFactoryById } from "../../reducers/factory.reducer";
import { ComponentStyles, FacilityStyles } from "../../styles";

const FacilityInformation = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const selectedFactoryData = useSelector(selectFactoryData);
  const [factoryData, setFactoryData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEditingInternal, setIsEditingInternal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const editMode = useSelector(selectEditModeState);

  useEffect(() => {
    if (selectedFactoryData) {
      setFactoryData(selectedFactoryData);
    }
  }, [selectedFactoryData]);

  const toggleEditInternal = () => {
    setIsEditingInternal(!isEditingInternal);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const isValidContact = (contact) => {
    return contact && Object.values(contact).some(value => value && value.trim() !== '');
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!factoryData) {
    return <div>No factory data available.</div>;
  }

  console.log(factoryData);

  return (
    <>
      <Card style={FacilityStyles.containerCard} sx={{ minHeight: "305px" }}>
        <CardContent>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Grid item>
              <Typography sx={FacilityStyles.headerOfTheCard}>
                General Site Information
                <Tooltip 
                  title="This information is maintained in the 4imprint database. To request updates, click the “Send 4imprint a note” button in the top-right corner."
                  placement="right"
                >
                  <InfoOutlinedIcon
                    sx={{ marginLeft: "8px", color: "#A0A5AD", verticalAlign: "middle" }}
                  />
                </Tooltip>
              </Typography>
              {editMode && (
                <EditIcon
                  fontSize={"2"}
                  sx={ComponentStyles.editButton}
                  onClick={toggleEditInternal}
                />
              )}
            </Grid>
            {/* <Grid item>
              <Button
                variant="outlined"
                onClick={toggleDrawer(true)}
                sx={{
                  color: "#6172F3",
                  "&:hover": {
                    color:"white",
                    backgroundColor: "#5667e2",
                  },
                  textTransform: "none",
                  width: "150px",
                  height: "40px",
                  borderRadius: "8px",
                  mr: 1
                }}
              >
                More Information
              </Button>
              
              <Button
                variant="outlined"
                onClick={toggleTierMapDrawer(true)}
                sx={{
                  color: "#6172F3",
                  "&:hover": {
                    color:"white",
                    backgroundColor: "#5667e2",
                  },
                  textTransform: "none",
                  width: "100px",
                  height: "40px",
                  borderRadius: "8px",
                  mr:"-24px"
                }}
              >
                Tier Map
              </Button>
            </Grid> */}
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            {/* <Grid item>
              <Typography>
                <span style={ComponentStyles.fieldName}>Suppliers:</span>{" "}
                <Link
                  to={`/suppliers/${factoryData.supplierId}`}
                  style={FacilityStyles.linkText}
                >
                  {factoryData?.supplierName || ""}
                  <img
                    style={{ paddingLeft: 8 }}
                    src="/ne_arrow.svg"
                    alt="arrow icon"
                  />
                </Link>
              </Typography>
            </Grid> */}

            <Grid item>
              <Typography>
                <span style={ComponentStyles.fieldName}>4imprint Site ID:</span>{" "}
                <span style={ComponentStyles.fieldValue}>
                  {factoryData.metadata?.factoryId || "-"}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}> Street Address</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.streetAddress || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}> City, State/Province, Country

              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {`${factoryData?.metadata?.city || "-"}, ${factoryData?.metadata?.stateProvince || "-"}, ${factoryData?.metadata?.country || "-"}`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}> Zip Code</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.zipCode || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ pr: 3 }}>
              <Typography sx={ComponentStyles.fieldName}>
                {" "}
                Products Produced
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData.metadata.productsProduced || "-"}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={0} justifyContent="space-between">
            <Grid item xs={3} sx={{ pr: 3 }}>
              <Typography sx={ComponentStyles.fieldName}>
                Primary Contact Person
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.contactPerson?.name || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ pr: 3 }}>
              <Typography sx={ComponentStyles.fieldName}>Position</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.contactPerson?.position || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ pr: 3 }}>
              <Typography sx={ComponentStyles.fieldName}>Phone</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.contactPerson?.phone || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ pr: 3 }}>
              <Typography sx={ComponentStyles.fieldName}>Email</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.contactPerson?.email || "-"}
              </Typography>
            </Grid>
          </Grid>

          {/* Additional contacts */}
          {factoryData?.metadata?.additionalContacts?.map((contact, index) => (
            isValidContact(contact) && (
              <Grid container spacing={0} justifyContent="space-between" key={index} sx={{ mt: 2 }}>
                
                <Grid item xs={3} sx={{ pr: 3 }}>
                  <Typography sx={ComponentStyles.fieldName}>Additional Contact Person</Typography>
                  <Typography sx={{ ...ComponentStyles.fieldValue, wordBreak: "break-word" }}>
                    {contact.contactPersonName || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ pr: 3 }}>
                  <Typography sx={ComponentStyles.fieldName}>Position</Typography>
                  <Typography sx={{ ...ComponentStyles.fieldValue, wordBreak: "break-word" }}>
                    {contact.contactPersonPosition || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ pr: 3 }}>
                  <Typography sx={ComponentStyles.fieldName}>Phone</Typography>
                  <Typography sx={{ ...ComponentStyles.fieldValue, wordBreak: "break-word" }}>
                    {contact.contactPersonPhone || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ pr: 3 }}>
                  <Typography sx={ComponentStyles.fieldName}>Email</Typography>
                  <Typography sx={{ ...ComponentStyles.fieldValue, wordBreak: "break-word" }}>
                    {contact.contactPersonEmail || "-"}
                  </Typography>
                </Grid>
              </Grid>
            )
          ))}
        </CardContent>
      </Card>
      <FacilityInformationDrawer
        factoryData={factoryData}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
      />

      {/* 4imprint Specific */}
    </>
  );
};

export default FacilityInformation;
