import React from "react";
import { List, ListItem, ListItemText, Typography, Link } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";
import { selectFactoryData } from "../../selectors/factory.selector";
import { selectAuditData } from "../../selectors/audit.selector";
import { selectSupplierResources } from "../../selectors/supplier.selector";
import { selectOrganisationResources } from "../../selectors/login.selector";

const AuditReportResources = () => {
  const selectedAuditData = useSelector(selectAuditData);
  const selectedFactoryData = useSelector(selectFactoryData);
  const supplierResources = useSelector(selectOrganisationResources);

  return (
    <List sx={{ width: "100%", pl: 3, pr: 1 }}>
      {Object.keys(supplierResources).map((section) => (
        <div key={section}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ mt: 2, color: "#475467", fontSize: 16, fontWeight: 700 }}
          >
            {section}
          </Typography>
          <ul>
            {supplierResources[section].map((file) => (
              <ListItem key={file.key} sx={{ p: 1 }}>
                <Link
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#45464E",
                    textDecoration: "underline",
                    textDecorationColor: "#45464E",
                    "&:hover": {
                      textDecorationColor: "rgba(69, 70, 78, 0.8)",
                    },
                  }}
                  href={file.fileUrl}
                  target="_blank"
                >
                  {file.key}
                </Link>
              </ListItem>
            ))}
          </ul>
        </div>
      ))}
    </List>
  );
};

export default AuditReportResources;
