import React, { useEffect } from "react";
import { Grid, Box } from "@mui/material";

import SupplierTable from "../components/supplierTable.component";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import FactoryTableDataGrid from "../components/factoryTableDataGrid.component";
import SupplierFactoryList from "../components/SupplierDetails/supplierFactoryList.component";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSupplierDataFromLogin,
  selectSupplierId,
} from "../selectors/login.selector";
import { setSupplierDataFromLogin } from "../reducers/supplier.reducer";
import { selectSupplierData } from "../selectors/supplier.selector";
const Factories = () => {
  const dispatch = useDispatch();
  const supplierId = useSelector(selectSupplierId);
  const supplierData = useSelector(selectSupplierData);
  const supplierDataFromLogin = useSelector(selectSupplierDataFromLogin);

  useEffect(() => {
    if (!supplierData && supplierId)
      dispatch(setSupplierDataFromLogin(supplierDataFromLogin));
  }, []);
  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSideNavbar />
      <Box sx={{ flex: 1, overflow: "auto" }}>
        <SupplierFactoryList />
      </Box>
    </Box>
  );
};

export default Factories;
